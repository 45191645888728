<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name:"App"
}
</script>

<style>
.docxFile .docx-wrapper>section.docx{
	box-shadow: none;
}
</style>